<template>
    <div class="container fluid">

        <div class="row">

            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>

            <div class="col-lg-12">
                
                <div class="card elevation-5">
                    <div class="card-body">
                        <v-spacer></v-spacer>
                        <div class="row">
                            <div class="col-lg-3 col-6">
                                <v-select v-model="millModel" :items="millLists" default="" item-value="mill_id" item-text="mill_name" label="Ship From(Mill)" clearable dense prepend-icon="mdi-factory"></v-select>
                            </div>
                            <div class="col-lg-3 col-6">
                                <v-select v-model="regionModel" :items="regionLists" default="" item-value="region" item-text="region" label="Region" :disabled="disabled_region == true" @change="(event) => updateOfficeDropdown(event)" clearable dense prepend-icon="mdi-city"></v-select>
                            </div>
                            <div class="col-lg-3 col-6">
                                <v-select v-model="officeModel" :items="officeLists" default="" item-value="office_id" item-text="office" label="branch" :disabled="disabled_office == true" @change="(event) => updateSalesmanDropdown(event)" clearable dense prepend-icon="mdi-home-variant"></v-select>
                            </div>
                            <div class="col-lg-3 col-6">
                                <v-select v-model="salesmanModel" :items="salesmanLists" default="" item-value="SalesId" item-text="NamaSales" label="Salesman" :disabled="disabled_salesman == true" clearable dense prepend-icon="mdi-account"></v-select>
                            </div>
                            <div class="col-lg-3 col-6">
                                <v-autocomplete v-model="deliveryIDModel" :items="deliveryIDLists" default="" item-value="deliv_id" :item-text="item => item.deliv_id +' - '+ item.receiver" label="Enter Delivery ID" clearable dense prepend-icon="mdi-truck" @keyup.enter="updateDeliveryDropdown()" @change="(event) => emptyDeliveryID(event)" @mouseenter="(event) => emptyDeliveryID(event)" id="delivery_id">
                                    <template v-slot:no-data><v-list-item>
                                        <v-list-item-title>Put <strong>Delivery ID</strong>, then press enter</v-list-item-title></v-list-item>
                                    </template>
                                </v-autocomplete>
                            </div>
                            <div class="col-lg-3 col-6">
                                <v-autocomplete v-model="orderIdModel" :items="orderIdLists" default="" item-value="CustomerOrderNo" :item-text="item => item.CustomerOrderNo +' - '+ item.NamaCustomer" label="Enter Order ID" clearable dense prepend-icon="mdi-cart" @keyup.enter="updateOrderIdDropdown()" @change="(event) => emptyOrderID(event)" @mouseenter="(event) => emptyOrderID(event)" id="order_id">
                                    <template v-slot:no-data><v-list-item>
                                        <v-list-item-title>Put <strong>Order ID</strong>, then press enter</v-list-item-title></v-list-item>
                                    </template>
                                </v-autocomplete>
                            </div>
                            <div class="col-lg-3 col-6">
                                <v-dialog ref="dialog" v-model="dateStartModal" :return-value.sync="dateStartModel" persistent width="290px" >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateStartModel" label="Start Date" readonly v-bind="attrs" v-on="on" dense clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateStartModel" :show-current="true" @click:date="$refs.dialog.save(dateStartModel)" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="dateStartModal = false">Cancel</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-3 col-6">
                                <v-dialog ref="dialog2" v-model="dateEndModal" :return-value.sync="dateEndModel" persistent width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateEndModel" label="End Date" readonly v-bind="attrs" v-on="on" dense clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateEndModel" :show-current="true" @click:date="$refs.dialog2.save(dateEndModel)" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="dateEndModal = false">Cancel</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-3">
                                <v-select v-model="typeModel" :items="typeLists" default="" item-value="type_id" item-text="type_text" label="Order Type" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-3">
                                <v-select v-model="usingActivityModel" :items="usingActivityLists" default="" item-value="value" item-text="text" label="Driver Activity" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-6">
                                <v-btn class="mt-3" color="primary" elevation="2" small rounded @click="getData()">Search<v-icon right dark>mdi-magnify</v-icon></v-btn>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col-lg-12 cardResult">
                <div class="card elevation-5">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-6 col-lg-6 text-left"><h5 class="card-title">Result</h5></div>
                            <div class="col-6 col-lg-6 text-right"><h5 class="card-title">Total Wgt. : {{ totalWgt ? totalWgt : '0' }} Kg</h5></div>
                        </div>
                        
                            <v-data-table :headers="headers" :items="itemLists" class="elevation-1" page-count="5" :search="searchItem" :loading="loadingDatatable" >
                                <template v-slot:top>
                                    <v-toolbar flat color="white">
                                        <div class="d-flex w-25">
                                            <v-text-field v-model="searchItem" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                        </div>
                                    </v-toolbar>
                                </template>
                                <template v-slot:[`item.showDetails`]="{ item }">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-chip @click="showItemDetails(item)" color="primary" small link dark v-on="on">Details</v-chip>
                                        </template>
                                        <span>Show Detail</span>
                                    </v-tooltip>
                                </template>
                            </v-data-table>
                    </div>
                </div>
            </div>

        </div>

        <!-- <div class="modal fade modal-xl" id="detailModal" tabindex="-1" aria-labelledby="detailModalLabel" aria-hidden="true">
            <div class="modal-dialog" style="max-width: 80%;">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="detailModalLabel">Customer : {{ detailModalTitle }} <br /> Order No : {{ detailModalTitle2 }}</h5>
                </div>
                <div class="modal-body">
        
                    <v-data-table :headers="headersDetails" :items="itemDetailLists" class="elevation-1" page-count="5" :loading="loadingDatatableDetail" dense>
                        <template v-slot:[`item.no`]="{ index }">
                            {{ index + 1 }}
                        </template>
                        <template v-slot:[`item.Panjang`]="{ item }">
                            {{ item.Panjang + ' m' }}
                        </template>
                    </v-data-table>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger text-white" data-bs-dismiss="modal">Close</button>
                </div>
                </div>
            </div>
        </div> -->

        <v-dialog v-model="dialogDetail" max-width="80%">
            <div class="card elevation-5">
                <div class="card-body">
                    <h5 class="modal-title" id="detailModalLabel">Customer : {{ detailModalTitle }} <br /> Order No : {{ detailModalTitle2 }}</h5>
                    <v-data-table :headers="headersDetails" :items="itemDetailLists" class="elevation-1" page-count="5" :loading="loadingDatatableDetail" dense>
                        <template v-slot:[`item.no`]="{ index }">
                            {{ index + 1 }}
                        </template>
                        <template v-slot:[`item.Panjang`]="{ item }">
                            {{ item.Panjang + ' m' }}
                        </template>
                    </v-data-table>
                </div>
                <div class="card-footer text-right">
                    <v-btn class="mt-3" color="red" elevation="2" small rounded @click="dialogDetail = !dialogDetail" dark>Close</v-btn>
                </div>
            </div>
        </v-dialog>

    </div>
    
</template>

<script>

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'E-Kencana',
                disabled: false,
                href: '/admin/kmb',
                },
                {
                text: 'E-Reports',
                disabled: false,
                href: '#',
                },
                {
                text: 'Delivery Report',
                disabled: true,
                href: '#',
                },
            ],
            millModel: '',
            millLists: [],
            regionModel: '',
            regionLists: [],
            officeModel: '',
            officeLists: [],
            salesmanModel: '',
            salesmanLists: [],
            deliveryIDModel: '',
            deliveryIDLists: [],
            orderIdModel: '',
            orderIdLists: [],
            typeModel: '',
            typeLists: [
                { type_id: 'O', type_text: 'CUSTOMER' },
                { type_id: 'I', type_text: 'INTERNAL' },
                { type_id: 'T', type_text: 'CONTRACT' },
                { type_id: 'B', type_text: 'SPECIAL' },
                { type_id: 'G', type_text: 'EXPRESS' }
            ],
            dateStartModel: '',
            dateEndModel: '',
            dateStartModal: false,
            dateEndModal: false,
            disabled_region: false,
            disabled_office: false,
            disabled_salesman: false,
            itemLists: [],
            headers: [
                { text: '', value: 'showDetails', align: 'center', class: 'primary--text blue lighten-5' },
                { text: 'SHIP DATE', value: 'SHIP_DATE', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'DELIV. NO', value: 'DELIV_ID', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'ORDER DATE', value: 'ORDER_DATE', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'ORDER ID', value: 'ORDER_ID', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'WEIGHT(Kg)', value: 'WEIGHT', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'SALESMAN', value: 'SALESMAN', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'CUSTOMER', value: 'CUSTOMER_NAME', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'VEHICLE NO.', value: 'VEHICLE_ID', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'DRIVER', value: 'DRIVER_NAME', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'DRIVER PHONE', value: 'DRIVER_PHONE', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'DT LEAVE', value: 'DT_LEAVE', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'DT HOME', value: 'DT_HOME', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'DT DROP', value: 'DT_DROP', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'DESTINATION', value: 'SHIP_TO', align: 'left', class: 'primary--text blue lighten-5' },
            ],
            itemDetailLists: [],
            headersDetails: [
                { text: 'NO.', value: 'no', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'DESC.', value: 'NamaBarang', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'LENGTH', value: 'length_ship', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'ORDER QTY.', value: 'qty_order', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'SHIP QTY.', value: 'qty_ship', align: 'right', class: 'primary--text blue lighten-5' },
            ],
            loadingDatatableDetail: false,
            searchItem: '',
            loadingDatatable: false,
            detailModalTitle: '',
            detailModalTitle2: '',
            userGroup: null,
            appl_id: 'E-REPORTS',
            totalWgt: '0',
            dialogDetail: false,
            usingActivityModel: '',
            usingActivityLists: [
                { text: '', value: ''},
                { text: 'YES', value: 'Y'},
                { text: 'NO', value: 'N'},
            ]

        }
    },
    async mounted(){
        
        this.initialize()
        

    },
    methods:{
        
        async initialize(){
            this.$store.dispatch('setOverlay', true)
            document.querySelector(".cardResult").style.display = "none";

            this.dateStartModel = this.currentDate()
            this.dateEndModel = this.currentDate()

            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/ShippingReport`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {
                this.millLists = res.data.mill
                if(res.data.region.length == 1){
                    this.regionModel = res.data.region[0].region
                    this.regionLists = res.data.region
                    this.disabled_region = true
                } else {
                    this.regionLists = res.data.region
                    this.disabled_region = false
                }
                if(res.data.office.length == 1){
                    this.officeModel = res.data.office[0].office_id
                    this.officeLists = res.data.office
                    this.disabled_office = true
                } else {
                    this.officeLists = res.data.office
                    this.disabled_office = false
                }
                if(res.data.sales.length == 1){
                    this.salesmanModel = res.data.sales[0].SalesId
                    this.salesmanLists = res.data.sales
                    this.disabled_salesman = true
                } else {
                    this.salesmanLists = res.data.sales
                    this.disabled_salesman = false
                }
                this.$store.dispatch('setOverlay', false)
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })
        },

        async getData(){

            if(this.millModel == null || this.millModel == ''){
                Swal.fire({
                        text: 'Please Select Mill',
                        icon: 'warning',
                    })
                return false
            }

            this.totalWgt = '0'

            // document.querySelector(".cardResult").style.display = "block";
            $('.cardResult').hide()
            this.itemLists = []
            this.loadingDatatable = true
            this.$store.dispatch('setOverlay', true)

            if (this.dateStartModel) {
                this.dateStartFormatted = new Date(this.dateStartModel).toISOString().substr(0, 10).replace(/-/g,"")
            } else  {
                this.dateStartFormatted = ''
            }

            if (this.dateEndModel) {
                this.dateEndFormatted = new Date(this.dateEndModel).toISOString().substr(0, 10).replace(/-/g,"")
            } else  {
                this.dateEndFormatted = ''
            }

            await axios.post(`${process.env.VUE_APP_URL}/api/kmb/ShippingReport/getData`, { 

                    mill: this.millModel ? this.millModel : '',
                    region: this.regionModel ? this.regionModel : '',
                    office: this.officeModel ? this.officeModel : '',
                    sales: this.salesmanModel ? this.salesmanModel : '',
                    deliveryID: this.deliveryIDModel ? this.deliveryIDModel : '',
                    orderid: this.orderIdModel ? this.orderIdModel : '',
                    type: this.typeModel ? this.typeModel : '',
                    usingActivity: this.usingActivityModel ? this.usingActivityModel : '',
                    dateFrom: this.dateStartFormatted,
                    dateTo: this.dateEndFormatted,

                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                },
            )
            .then(res => {
                if(res.data.result.length != 0){
                    // document.querySelector(".cardResult").style.display = "block";
                    $('.cardResult').show()
                    this.itemLists = res.data.result
                    this.totalWgt = res.data.totalWgt
                    this.$store.dispatch('setOverlay', false)
                    this.loadingDatatable = false
                } else {
                    this.$store.dispatch('setOverlay', false)
                    this.loadingDatatable = false
                    Swal.fire({
                        text: 'Data not found !',
                        icon: 'warning',
                    })
                }
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        async showItemDetails(id){
            // $('#detailModal').modal('show')
            this.dialogDetail = true
            this.detailModalTitle = id.CUSTOMER_NAME 
            this.detailModalTitle2 = id.ORDER_ID
            this.itemDetailLists = []
            this.loadingDatatableDetail = true

            await axios.post(`${process.env.VUE_APP_URL}/api/kmb/ShippingReport/getDataDetails`, {
                    id: id.ORDER_ID,
                    deliv_id: id.DELIV_ID
                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {
                this.loadingDatatableDetail = false
                this.itemDetailLists = res.data.result
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })
        },

        updateOfficeDropdown(id){
            if(id){
                axios.get(`${process.env.VUE_APP_URL}/api/kmb/ShippingReport/getOfficeById?regionid=${id}`, { 
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {

                    this.officeLists = res.data.office
                    this.disabled_office = false
                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401'){
                        this.$router.push('/');
                    }

                })

            } else {
                axios.get(`${process.env.VUE_APP_URL}/api/kmb/ShippingReport/getOfficeById?regionid=all`, { 
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {
                    this.officeLists = res.data.office
                    this.disabled_office = false
                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401'){
                        this.$router.push('/');
                    }

                })
            }
        },

        updateSalesmanDropdown(id){
            if(id){

                axios.get(`${process.env.VUE_APP_URL}/api/kmb/ShippingReport/getSalesmanById?officeid=${id}`, { 
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {
                    this.salesmanLists = res.data.sales
                    this.disabled_salesman = false
                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401'){
                        this.$router.push('/');
                    }

                 })
            } else {
                axios.get(`${process.env.VUE_APP_URL}/api/kmb/ShippingReport/getSalesmanById?officeid=all`, { 
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {
                    this.salesmanLists = res.data.sales
                    this.disabled_salesman = false
                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401'){
                        this.$router.push('/');
                    }

                })
            }
        },

        updateDeliveryDropdown()
        {
            var id = $('#delivery_id').val()
            if(id){
                axios.get(`${process.env.VUE_APP_URL}/api/kmb/ShippingReport/getDeliveryID?deliveryID=${id}`, { 
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                    })
                    .then(res => {
                        if(res.data.result.length != 0){
                            this.deliveryIDLists = res.data.result
                        } else {
                            Swal.fire({
                                text: 'Delivery ID not found, please try again !',
                                icon: 'warning',
                            })
                        }
                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401'){
                        this.$router.push('/');
                    }

                })
            } else {
                this.deliveryIDLists = []
            }
        },

        updateOrderIdDropdown()
        {
            var id = $('#order_id').val()
            if(id){
                axios.get(`${process.env.VUE_APP_URL}/api/kmb/ShippingReport/getOrderID?orderid=${id}`, { 
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                    })
                    .then(res => {
                        if(res.data.result.length != 0){
                            this.orderIdLists = res.data.result
                        } else {
                            Swal.fire({
                                text: 'Order ID not found, please try again !',
                                icon: 'warning',
                            })
                        }
                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401'){
                        this.$router.push('/');
                    }

                })
            } else {
                this.orderIdLists = []
            }

        },

        emptyOrderID(id)
        {
            if(id == null){
                this.orderIdLists = []
            }
        },

        emptyDeliveryID(id)
        {
            if(id == null){
                this.deliveryIDLists = []
            }
        },

        currentDate() {
            const current = new Date();
            // const date = `${current.getFullYear()}-${current.getMonth()+1}-${current.getDate()}`;      
            const date = current.toISOString().slice(0,10);
            return date;
        }

    }
    
}
</script>

<style scoped>

    .v-text-field {
        padding-top: 10px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 1em;
        padding-bottom: 10px; 
        padding-left: 10px;
    }

    .cardCustom{
        padding-top: 10px;
    }

    .container{
        margin-bottom: 100px !important;
    }

    .v-text-field >>> input {
        font-size: 1em !important;
        font-weight: 100 !important;
        text-transform: capitalize !important;
    }
    .v-text-field >>> label {
        font-size: 0.8em !important;
    }
    .v-text-field >>> button {
        font-size: 0.8em !important;
    }

</style>